import axios from 'axios';
import _ from 'lodash';
import cookie from 'react-cookies';
import { call, put, select } from 'redux-saga/effects';
import {
  isAccessVerified,
  isPatientContextError,
  isVerifyingAccess,
  patientContextErrorMessage,
  selectContext,
  selectPatient,
  selectUser,
  userOrganizations,
  userOrganizationId,
  patientContextId,
} from '../state';
import fetchUser from './fetchUser';
import fetchContext from './context/fetchContext';
import fetchPatientContext from './FHIR/patient/fetchPatientContext';
import Session from '../services/Session';
import { updateOrganization } from '../services/axios';
import EnvironmentSettings from '../services/EnvironmentSettings';
import getOrgIdForRequest from './FHIR/serviceRequest/getOrgIdForRequest';
import { history } from '../store';
import CoreApp from '../services/CoreApp';
import fetchPatient from './FHIR/patient/fetchPatient';
import { init, timeEvent, trackEvent } from '../services/AnalyticsService';
import { logError } from '../helpers/log';

/**
 * Set user's organization and save to cookie
 * @param {array} orgs
 * @param {int|null} id
 * @returns {IterableIterator<*>}
 */
function* setOrganization(orgs, id = null) {
  if (id) {
    // using ._ instead of find because it cause an IE issue
    const foundOrg = _.find(orgs, (org) => org.id === parseInt(id, 10));
    if (!foundOrg) {
      Session.unauthorized('invalid_organization');
    }
    // FORM-431: The cookies must be reset with the provided organization since it can be changed
    // when a user like a RNN changes the site in clinical app
    cookie.remove(`scope_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`);
    cookie.remove(`request_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`);
    cookie.save(`scope_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`, id, { domain: EnvironmentSettings.domainServer, path: '/' });
    cookie.save(`request_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`, id, { domain: EnvironmentSettings.domainServer, path: '/' });
    yield put(userOrganizationId(id));
    yield call(updateOrganization, id);
  } else if (orgs.length === 1) {
    yield put(userOrganizationId(orgs[0].id));
    // Reset org saved in cookies
    cookie.remove(`scope_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`);
    cookie.remove(`request_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`);

    cookie.save(`scope_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`, orgs[0].id, { domain: EnvironmentSettings.domainServer, path: '/' });
    cookie.save(`request_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`, orgs[0].id, { domain: EnvironmentSettings.domainServer, path: '/' });

    yield call(updateOrganization, orgs[0].id);
  } else {
    const orgId = cookie.load(`scope_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`);
    const requestOrg = cookie.load(`request_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`);
    // using ._ instead of find because it cause an IE issue
    const checkCurrentOrg = _.find(orgs, (org) => org.id === parseInt(orgId, 10));
    if (orgId && (checkCurrentOrg !== undefined)) {
      if (!requestOrg || requestOrg !== orgId) {
        cookie.save(`request_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`, orgId, { domain: EnvironmentSettings.domainServer, path: '/' });
      }
      yield put(userOrganizationId(orgId));
      yield call(updateOrganization, orgId);
    } else {
      cookie.remove(`scope_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`, { domain: EnvironmentSettings.domainServer, path: '/' });
      cookie.remove(`request_organization_id${EnvironmentSettings.stage ? '_dev' : ''}`, { domain: EnvironmentSettings.domainServer, path: '/' });
    }
  }
}

/**
 * Verify user's access, load patient context, and set user's organization
 * @returns {IterableIterator<AxiosPromise<any>|*>}
 */
export default function* verifyAccess() {
  yield put(isVerifyingAccess(true));
  try {
    const response = yield axios.get('/verify/request');
    const orgs = response.data.organizations.sort((a, b) => {
      if (a.display_name === b.display_name) return 0;
      if (a.display_name < b.display_name) return -1;
      if (a.display_name > b.display_name) return 1;
    });
    yield put(userOrganizations(orgs));
    yield call(fetchContext);
    const context = yield select((state) => selectContext(state));
    if (!context) {
      // If failed to load context, we cannot continue
      return;
    }
    let orgId;

    if (context.ehr_patient_context_id) {
      // Load patient context and set user organization
      yield call(fetchPatientContext);
      const patient = yield select((state) => selectPatient(state));
      if (_.find(orgs, (org) => org.id === parseInt(patient.organization_id, 10))) {
        // Handle PILOT-11898
        // User provisioned at same level as patient
        orgId = patient.organization_id;
      } else if (_.find(orgs, (org) => org.id === context.auth_provider.organization_id)) {
        // Default to the auth provider's organization
        // User may be provisioned at headquarters, but patient provisioned at site level
        orgId = context.auth_provider.organization_id;
      } else {
        // This probably should not happen -- user doesn't have access to the patient site nor the
        // auth provider org? How did they log in? Maybe access was removed after login.
        yield put(isPatientContextError(true));
        yield put(patientContextErrorMessage("Unable to set user organization scope. You do not have access to the EHR patient's organization."));
        return;
      }
      // Verify that the referral order id has not already been used
      // Send user back to clinical app or open existing draft eConsult as appropriate
      if (context.smart?.referral_order_id) {
        let data = {};
        try {
          const smartFindResponse = yield axios.get('/econsults/smart/find');
          ({ data } = smartFindResponse);
        } catch (e) {
          if (e.response?.status === 403 && e.response?.data) {
            ({ data } = e.response);
          } else {
            // Display error since we are unable to verify the referral order id can be used
            yield put(isPatientContextError(true));
            yield put(patientContextErrorMessage(`Unable to verify referral order id: ${context.smart.referral_order_id}. Please try your request again.`));
            return;
          }
        }
        if (data.can_create_request === false) {
          // Redirect back to clinical app
          window.location.href = CoreApp.patientContextUrl();
          return;
        }
        if (data.econsult_fhir_id) {
          // Load existing eConsult for referral order id
          history.replace(`/econsults/create-request/${data.econsult_fhir_id}`);
        }
      }
    }

    let fhirId = null;
    if (!orgId) {
      const pattern = /^\/econsults\/create-request\/([^\/]+)\/?(.*)$/;
      const match = window.location.pathname.match(pattern);
      if (match) {
        fhirId = match[1];
        orgId = yield call(getOrgIdForRequest, { fhirId });
      }
    }

    const pattern = /^\/patient\/([^\/]+)\/?(.*)$/;
    const patientMatch = window.location.pathname.match(pattern);
    // If the form is open from the patient form we have a different URL
    // also we need to get the patient ID from this new URL
    if (patientMatch) {
      const patientID = patientMatch[1];
      const payload = {
        patientID,
        patientIDType: 'aristamd_patient_id',
      };
      yield call(fetchPatient, { payload });
      yield put(patientContextId(patientID));
    }

    // Set user's organization
    yield call(setOrganization, orgs, orgId);

    // Fetch user after set organization since organization settings will
    // come back as part of user in user.organization.settings
    yield call(fetchUser, { payload: Session.userId });
    const user = yield select((state) => selectUser(state));
    if (user) {
      init(user);
      timeEvent('Submit eConsult');
      // track event just the first time request is saved as draft
      if (!fhirId) {
        trackEvent('Start eConsult Request Form');
      }
      yield put(isAccessVerified(true));
    }
  } catch (e) {
    yield put(userOrganizations(null));
    yield put(userOrganizationId(null));

    // If the app is reloaded on the confirmation page, some data won't be there.
    const isPageAccessedByReload = (
      (window.performance.navigation && window.performance.navigation.type === 1)
        || window.performance
          .getEntriesByType('navigation')
          .map((nav) => nav.type)
          .includes('reload')
    );
    if (isPageAccessedByReload) {
      window.location.href = `https://app${EnvironmentSettings.domainServer}`;
    }

    let logErrorObject;
    if (e.isAxiosError) {
      logErrorObject = { ...e.response };
    } else {
      logErrorObject = {
        name: e.name,
        message: e?.message,
        stack: e.stack,
      };
    }

    let error = '';
    if (e.response && e.response.data && e.response.data.error) {
      error = e.response.data.error;
    }

    logError('Unable to verify access', [logErrorObject], true)
      .finally(() => Session.unauthorized(error));
  }
  // Restore original state
  yield put(isVerifyingAccess(false));
}
