import { findByAttributeValue } from '../utils';

// Default genders
export const DEFAULT_GENDERS = [
  {
    code: 'male',
    display: 'Male',
    abbreviation: 'M',
  },
  {
    code: 'female',
    display: 'Female',
    abbreviation: 'F',
  },
];

// Additional gender options
export const ADDITIONAL_GENDERS = [
  {
    code: 'x',
    display: 'X',
    abbreviation: 'X',
  },
  {
    code: 'non-binary',
    display: 'Non-Binary',
    abbreviation: 'NB',
  },
  {
    code: 'unknown',
    display: 'Unknown',
    abbreviation: 'U',
  },
];

/**
 * Get gender for given code
 * @param {string} code
 * @returns {Object|undefined}
 */
export const getGenderByCode = (code) => findByAttributeValue([...DEFAULT_GENDERS, ...ADDITIONAL_GENDERS], 'code', code);

/**
 * Get gender for given abbreviation
 * @param {string} abbreviation
 * @returns {Object|undefined}
 */
 export const getGenderByAbbreviation = (abbreviation) => findByAttributeValue([...DEFAULT_GENDERS, ...ADDITIONAL_GENDERS], 'abbreviation', abbreviation);

/**
 * Get all genders based on organization settings
 * @param {array} enabledGenders organization.configuration.settings.enabled_genders
 * @returns {array}
 */
export const getGenders = (enabledGenders) => {
  if (enabledGenders && enabledGenders.length > 0) {
    const additionalGenders = enabledGenders.map((gender) => getGenderByCode(gender));
    return [...DEFAULT_GENDERS, ...additionalGenders];
  }
  return DEFAULT_GENDERS;
};

/**
 * Check if given code should be sent/received from FHIR api as gender 'other'
 * @param {string} code
 * @returns {boolean}
 */
export const isGenderOther = (code) => ['x', 'non-binary'].includes(code);

/**
 * Get gender display text for given code
 * @param {string} code
 * @returns {string}
 */
export const getGenderDisplay = (code) => {
  const gender = getGenderByCode(code);
  return gender ? gender.display : '';
};

/**
 * Get gender abbreviation for given code
 * @param {string} code
 * @returns {string}
 */
export const getGenderAbbreviation = (code) => {
  const gender = getGenderByCode(code);
  return gender ? gender.abbreviation : '';
};

/**
 * Parses patient to get the gender based on gender_identity
 * @param {object} patient
 * @return {string}
 */
export const parsePatientGender = (patient) => {
  // If gender is 'other', use gender_identity value (e.g. 'x', 'non-binary') as the gender
  if (patient.gender === 'other') {
    return patient.gender_identity;
  }
  return patient.gender;
};
